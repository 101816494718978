<template>
  <div class="app-container">
    <div class="full">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="item in auditData" :key="item.name" :label="item.label" :name="item.name">
          <el-form :inline="true">
            <el-form-item label="区域">
              <el-select clearable style="width:100%" v-model="params.city">
                <el-option
                  v-for="(item, index) in cityList"
                  :key="'item_' + index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户身份">
              <el-select clearable style="width:100%" v-model="params.roleCode">
                <el-option
                  v-for="(item, index) in roleList"
                  :key="'item_' + index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label="状态">
              <el-select clearable style="width:100%" v-model="params.status">
                <el-option
                  v-for="(item, index) in statusList"
                  :key="'item_' + index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="用户名">
              <el-input v-model="params.nickname"></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-model="params.idCardCode"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
              <el-button type="primary" @click="exportExcel">导出</el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            border
            stripe
            default-expand-all
            align="center"
          >
            <el-table-column prop="name" label="参会地">
              <template slot-scope="scope">
                {{ scope.row ? scope.row.city : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderNum" label="会议开始时间">
              <template slot-scope="scope">
                {{ scope.row ? scope.row.trainStartTime : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderNum" label="会议结束时间">
              <template slot-scope="scope">
                {{ scope.row ? scope.row.trainEndTime : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="perms" label="姓名">
              <template slot-scope="scope">
                {{ scope.row ? scope.row.nickname : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="icon" label="电话">
              <template slot-scope="scope">
                {{ scope.row ? scope.row.username : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderNum" label="身份证">
              <template slot-scope="scope">
                {{ scope.row ? scope.row.idCardCode : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="orderNum" label="状态">
              <template slot-scope="scope">
                {{
                  scope.row.status == 2 ? "审核通过" : "未审核"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="icon" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="scope.row.status == 1"
                  @click="exportRow(scope.row)"
                >
                  审核</el-button
                >
                <el-button
                  type="text"
                  v-if="scope.row.status == 2"
                  @click="backRow(scope.row)"
                >
                  撤销</el-button
                >
                <!-- <el-button type="text" @click="showSignStatus(scope.row)">
                  打卡记录</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="sizechange"
            @current-change="pagechange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :current-page="page.index"
            :page-size="page.size"
            :total="page.total"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
      <el-dialog
        title="是否确定审核通过？"
        :visible.sync="dialogVisible"
        width="300px"
        :before-close="handleClose1"
      >
        <!-- <el-input type="text" v-model="msg"></el-input> -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="exportsubmit('resolve')"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        title="签到记录"
        :visible.sync="signVisible"
        width="600px"
        :before-close="handleClose"
      >
        <el-table :data="signList" v-if="signVisible">
          <el-table-column prop="signTime" label="签到时间"> </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="signVisible = false"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      page: {
        index: 1,
        size: 10,
        total: 0,
      },
      statusList: [
        { name: "全部", value: "" },
        { name: "审核通过", value: "1" },
        { name: "未审核", value: "0" },
      ],
      roleList: ["物业企业", "主管部门", "协会", "业主代表"],
      params: {
        city: "",
        nickname: "",
        idCardCode: "",
        roleCode: "",
        status: "",
        statu: "",
      },
      cityList: [
        {
          name: "成都市",
        },
        {
          name: "南充市",
        },
        {
          name: "广元市",
        },
        {
          name: "巴中市",
        },
        {
          name: "广安市",
        },
        {
          name: "眉山市",
        },
        {
          name: "遂宁市",
        },
        {
          name: "达州市",
        },

        {
          name: "绵阳市",
        },
        {
          name: "资阳市",
        },

        {
          name: "德阳市",
        },
        {
          name: "内江市",
        },
        {
          name: "乐山市",
        },
        {
          name: "宜宾市",
        },
        {
          name: "泸州市",
        },
        {
          name: "凉山彝族自治州",
        },

        {
          name: "自贡市",
        },
        {
          name: "攀枝花市",
        },
        {
          name: "甘孜藏族自治州",
        },
        {
          name: "阿坝藏族羌族自治州",
        },
        {
          name: "雅安市",
        },
      ],
      dialogVisible: false,
      signVisible: false,
      msg: "", // 审核意见
      formData: {
        pid: "",
        openId: "",
      },
      signList: [],
      activeName: '1',
      auditData:[
        {
          name:'1',
          label:'未审核'
        },
        {
          name:'2',
          label:'审核通过'
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    sizechange(val) {
      this.page.size = val;
      this.getList();
    },
    pagechange(val) {
      this.page.index = val;
      this.getList();
    },
    handleClose1() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.signVisible = false;
    },
    getList(type) {
      this.params.current = type?1:this.page.index;
      this.params.size = type?10:this.page.size;
      this.params.statu = Number(this.activeName);
      this.$axios.post("/sys/sign/findList", this.params).then((res) => {
        this.tableData = res.data.data.records;
        this.page.total = res.data.data.total;
      });
    },
    exportRow(row) {
      this.formData.pid = row.dictionariesId;
      this.formData.openId = row.openId;
      this.dialogVisible = true;
    },

    backRow(row) {
      this.$confirm("是否确定撤销当前审核通过的数据,?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
            this.$axios.get(`/sys/user/revoke/${row.openId}`).then((res) => {
            this.$message({
              message: "撤回成功",
              type: "success",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
    /**
     * 签到记录
     */
    showSignStatus(row) {
      this.signList = row.lis;
      this.signVisible = true;
    },
    /**
     * 审核提交
     */
    exportsubmit(agree) {
      let status = agree == "resolve" ? 2 : agree == "reject" ? -1 : -1;
      this.formData.status = status;
      this.formData.msg = this.msg;

      this.$axios.post("sys/sign/update", this.formData).then((res) => {
        this.$message({
          message: "恭喜你，操作成功",
          type: "success",
        });
        this.getList();
        this.dialogVisible = false;
      });
    },
    /**
     * 导出列表
     */
    exportExcel() {
      // var xhr = new XMLHttpRequest();
      // xhr.open("GET", '/sys/user/export', true);
      // xhr.responseType = "blob";
      // xhr.onload = function() {
      //   if (xhr.status === 200) {
      //     cb(xhr.response);
      //   }
      // };
      // xhr.send();
      // this.$axios.get("/sys/user/export").then((res) => {
      //   console.log(res, 123132132);
      //   if (window.navigator.msSaveOrOpenBlob) {
      //     navigator.msSaveBlob(res.data, "注册用户.xls");
      //   } else {
      //     let blob = new Blob([res.data], { type: "application/octet-stream" });
      var link = document.createElement("a");
      var body = document.querySelector("body");
      //     // let urlAll =     window.URL.createObjectURL(blob),
      //     //     tempUrl = urlAll.split("/");
      //     // console.log(tempUrl)
      this.params;
      link.href =
        "http://sign.sccqhome.cn/api/sys/user/exportAdopt?city=" +
        this.params.city +
        "&nickname=" +
        this.params.nickname +
        "&roleCode=" +
        this.params.roleCode +
        "&idCardCode=" +
        this.params.idCardCode +
        "&status=" +
        this.params.status;
      link.download = "打卡用户.xls"; // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      //   }
      //   // this.$message({
      //   //   message: "恭喜你，操作成功",
      //   //   type: "success",
      //   // });
      //   // this.getList();
      //   // this.dialogVisible = false;
      // });
    },
    /**切换tabs**/
    handleClick(tab, event){
      this.page.index = 1
      this.page.size = 10
      this.getList(true)
    },
  },

  created() {},
  watch: {},
};
</script>

<style lang="scss" scoped>

</style>
